export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const LOADING_USERS = "LOADING_USERS";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";


export const GET_FARMERS_SUCCESS = "GET_FARMERS_SUCCESS";
export const GET_FARMER_FAIL = "GET_FARMER_FAIL";
export const LOADING_FARMER = "LOADING_FARMER";
export const ADD_FARMER_SUCCESS = "ADD_FARMER_SUCCESS";
export const ADD_FARMER_FAIL = "ADD_FARMER_FAIL";
export const UPDATE_FARMER_SUCCESS = "UPDATE_FARMER_SUCCESS";
export const UPDATE_FARMER_FAIL = "UPDATE_FARMER_FAIL";
export const DELETE_FARMER_SUCCESS = "DELETE_FARMER_SUCCESS";
export const DELETE_FARMER_FAIL = "DELETE_FARMER_FAIL";

export const GET_SOURCING_AGENTS_SUCCESS = "GET_SOURCING_AGENTS_SUCCESS";
export const GET_SOURCING_AGENTS_FAIL = "GET_SOURCING_AGENTS_FAIL";
export const ADD_SOURCING_AGENT_SUCCESS = "ADD_SOURCING_AGENT_SUCCESS";
export const ADD_SOURCING_AGENT_FAIL = "ADD_SOURCING_AGENT_FAIL";
export const LOADING_SOURCING_AGENTS = "LOADING_SOURCING_AGENTS";