export const BASE_URL = "https://dev-tie-service.tradebuza.com/api/v3";
export const AUTH_URL = "https://dev-auth-service.tradebuza.com/api/v3";
// export const REACT_APP_MAP_KEY = "AIzaSyAE5beByDMsRgJwMDLutqutRI_ZjxnyCJs";
// export const REACT_APP_WEATHER_KEY = "cf99876fe4c1fe9a5523b26afac5b0b1";

const config = {
    // BASE_URL: process.env.REACT_APP_BASE_URL,
    mapsKey: process.env.REACT_APP_MAP_KEY,
    // AUTH_URL: process.env.REACT_APP_AUTH_URL,
    weatherKey: process.env.REACT_APP_WEATHER_KEY,
}

export default config;
