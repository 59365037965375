export const GET_CLUSTERS = "GET_CLUSTERS";
export const GET_CLUSTERS_SUCCESS = "GET_CLUSTERS_SUCCESS";
export const GET_CLUSTERS_FAIL = "GET_CLUSTERS_FAIL";

export const GET_SUB_CLUSTERS = "GET_SUB_CLUSTERS";
export const GET_SUB_CLUSTERS_SUCCESS = "GGET_SUB_CLUSTERS_SUCCESS";
export const GET_SUB_CLUSTERS_FAIL = "GET_SUB_CLUSTERS_FAIL";

export const GET_FARM_LOTS = "GET_FARM_LOTS";
export const GET_FARM_LOTS_SUCCESS = "GET_FARM_LOTS_SUCCESS";
export const GET_FARM_LOTS_FAIL = "GET_FARM_LOTS_FAIL";