import { treeItemClasses } from "@mui/lab";
import {
  GET_FILTER_DETAILS,
  GET_VERIFICATION,
  GET_VERIFICATION_SUMMARY,
  IS_LOADING_VERIFICATION,
  GET_VERIFICATION_DETAILS,
} from "../types/VerificationTypes";

const initialState = {
  summary: [],
  loading: false,
  verificationData: [],
  filterDetails: [],
  verificationDetails: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case IS_LOADING_VERIFICATION:
      return {
        ...state,
        loading: true,
      };

    case GET_VERIFICATION_DETAILS:
      return {
        ...state,
        verificationDetails: payload,
        loading: false,
      };

    case GET_FILTER_DETAILS:
      return {
        ...state,
        filterDetails: payload,
        loading: false,
      };

    case GET_VERIFICATION_SUMMARY:
      return {
        ...state,
        summary: payload,
        loading: false,
      };

    case GET_VERIFICATION:
      return {
        ...state,
        verificationData: payload,
        loading: false,
      };

    default:
      return state;
  }
}
