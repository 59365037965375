export const GET_CROP_PROFILE = "GET_CROP_PROFILE";
export const GET_CROP_PROFILE_SUCCESS = "GET_CROP_PROFILE_SUCCESS";
export const GET_CROP_PROFILE_FAIL = "GET_CROP_PROFILE_FAIL";

export const GET_CROP_CALENDAR = "GET_CROP_CALENDAR";
export const GET_CROP_CALENDAR_SUCCESS = "GET_CROP_CALENDAR_SUCCESS";
export const GET_CROP_CALENDAR_FAIL = "GET_CROP_CALENDAR_FAIL";

export const GET_FARM_SEASONS = "GET_FARM_SEASONS";
export const GET_FARM_SEASONS_SUCCESS = "GET_FARM_SEASONS_SUCCESS";
export const GET_FARM_SEASONS_FAIL = "GET_FARM_SEASONS_FAIL";

export const GET_ACTIVITY_CATEGORIES = "GET_ACTIVITY_CATEGORIES";
export const GET_ACTIVITY_CATEGORIES_SUCCESS = "GET_ACTIVITY_CATEGORIES_SUCCESS";
export const GET_ACTIVITY_CATEGORIES_FAIL = "GGET_ACTIVITY_CATEGORIES_FAIL";
